import React, { useState } from 'react';
import './ScanSBIR.css';

function ScanSBIR({ onMatrixCreated, currentSBIR, apiKey, setSelectedTab, setComplianceMatrixStreamed, selectedFile, handleFileUploadMatrix, setComplianceMatrixStreaming, selectedRefFiles, analysisSBIR, setIsScanningModal }) {
  const [isLoading, setIsLoading] = useState(false);

  const ENV = process.env.NODE_ENV;

  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  const handleGenerateMatrix = async () => {
      setIsLoading(true);
      setIsScanningModal(true);
      handleFileUploadMatrix();
      setComplianceMatrixStreamed('');
      setComplianceMatrixStreaming(true);

      try {
          const formData = new FormData();
          formData.append('currentSBIR', currentSBIR);

          console.log(`Sending POST request to ${BASE_URL}/govex/analyze-sbir`);
          const response = await fetch(`${BASE_URL}/govex/analyze-sbir`, {
              method: 'POST',
              headers: {
                  'X-API-KEY': apiKey,
              },
              body: formData,
          });

          if (!response.ok) {
              throw new Error(`Response was not ok: ${response.statusText}`);
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let partialResponse = '';
          let finalJSON = '';

          while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              partialResponse += decoder.decode(value, { stream: true });

              // Process each chunk
              console.log("Received chunk:", partialResponse);

              // Check if the chunk contains valid JSON and update finalJSON accordingly
              try {
                  const parsedJSON = JSON.parse(partialResponse);
                  if (Object.keys(parsedJSON).length > 0) {
                      finalJSON = partialResponse; // Update with the last valid JSON received
                  }
                  // Clear partialResponse after successful parsing to avoid re-parsing
                  partialResponse = '';
              } catch (e) {
                  // If parsing fails, continue to accumulate data
                  console.log("Incomplete JSON, waiting for more data...");
              }
          }

          // Handle the final JSON
          if (finalJSON) {
              const jsonResponse = JSON.parse(finalJSON);
              console.log("Final JSON response received:", jsonResponse);
              onMatrixCreated(jsonResponse, null, null); // Pass the JSON data to the callback
          } else {
              console.error('No valid JSON response received');
          }
      } catch (error) {
          console.error('Error generating compliance matrix:', error);
          setIsScanningModal(false);
      } finally {
          setIsLoading(false);
      }
  };

  return (
    <div style={{ marginTop: '10px' }} className="tooltip-wrapper">
      <button 
        onClick={() => {
          if (analysisSBIR) {
            setSelectedTab("viewAnalyzedSBIR");
          } else {
            setComplianceMatrixStreaming(true);
            handleGenerateMatrix();
          }
        }}
        id="step12"
        disabled={isLoading || !selectedFile || !Object.values(selectedRefFiles).some(isSelected => isSelected)}
      >
        {isLoading ? (
          <div className="spinner"></div>
        ) : (
          analysisSBIR ? 'Next' : 'Analyze Grant'
        )}
      </button>
      {
        (!Object.values(selectedRefFiles).some(isSelected => isSelected) || !selectedFile || isLoading) && (
          <div className="tooltip">
            <ul>
              {!Object.values(selectedRefFiles).some(isSelected => isSelected) && (
                <li>Please select at least one reference file.</li>
              )}
              {!selectedFile && (
                <li>Please upload a solicitation file.</li>
              )}
              {isLoading && (
                <li>Analysis is in progress. Please wait...</li>
              )}
            </ul>
          </div>
        )
      }
    </div>
  );
}

export default ScanSBIR;
