import React from 'react';
import './TermsAndConditionsAndPrivacyPolicy.css'; // Import your CSS file for styling

function TermsAndConditionsAndPrivacyPolicy() {
  return (
    <div className="policy-container">

      <div className="policy-section introduction-section">
        <h2 className="policy-title">Introduction</h2>
        <p className="policy-text">
          Welcome to GovEx AI LLC. We offer a Software as a Service (SaaS) platform designed to automate proposal generation for uploaded Requests for Proposals (RFPs). By accessing and utilizing our services, you ("User") agree to comply with the following Terms and Conditions and acknowledge our Privacy Policy. If you do not agree, please discontinue use of our services.
        </p>
      </div>

      <div className="policy-section terms-section">
        <h2 className="policy-title">Terms and Conditions</h2>
        <p className="policy-text">
          These Terms and Conditions ("Terms") govern your use of the services provided by GovEx AI LLC ("we", "us", "our"). By accessing or using our services, you agree to these Terms. You are responsible for reviewing these Terms periodically for updates.
        </p>

        <h3 className="policy-subtitle">Token Usage</h3>
        <p className="policy-text">
          Your use of the platform is subject to a maximum token allowance, as specified in your agreement. Token usage will be monitored, and additional charges may apply if usage exceeds the agreed limits. Reports will be provided to notify users if token usage approaches these limits.
        </p>

        <h3 className="policy-subtitle">Fees and Payment Terms</h3>
        <p className="policy-text">
          Payment terms, including implementation fees and recurring charges, will be agreed upon during contract execution. Additional fees may apply for overages or customization requests. Payments are processed via Stripe or other approved methods. Late payments may incur penalties.
        </p>

        <h3 className="policy-subtitle">Confidentiality</h3>
        <p className="policy-text">
          Both parties agree to protect each other's Confidential Information as defined in the agreement. Reasonable security measures will be implemented to safeguard such information.
        </p>

        <h3 className="policy-subtitle">Service Level Agreements (SLAs)</h3>
        <p className="policy-text">
          We strive to maintain high availability and provide responsive support as outlined in your SLA. Remedies, such as service credits, may be provided for failing to meet uptime or support commitments.
        </p>

        <h3 className="policy-subtitle">License and Usage Restrictions</h3>
        <p className="policy-text">
          Users are granted a non-exclusive, non-transferable license for internal use of the platform. Unauthorized distribution, resale, or external use without prior approval is prohibited.
        </p>

        <h3 className="policy-subtitle">Audit Rights</h3>
        <p className="policy-text">
          We reserve the right to audit your usage of the platform to ensure compliance with these Terms. Audits will be conducted as deemed necessary, and any non-compliance may result in additional charges or suspension of access.
        </p>

        <h3 className="policy-subtitle">Publicity</h3>
        <p className="policy-text">
          We may use your company name and logo in marketing materials with prior written consent. Both parties agree not to disparage one another publicly.
        </p>

        <h3 className="policy-subtitle">Intellectual Property</h3>
        <p className="policy-text">
          Each party retains ownership of its pre-existing intellectual property. Any custom developments created during the contract remain the property of GovEx AI and are licensed to you under these Terms.
        </p>

        <h3 className="policy-subtitle">Termination</h3>
        <p className="policy-text">
          The agreement remains in effect for an initial term and automatically renews unless terminated with prior notice. Either party may terminate for cause in case of material breach or other outlined circumstances.
        </p>

        <h3 className="policy-subtitle">Dispute Resolution</h3>
        <p className="policy-text">
          Any disputes will be governed by the laws of Florida and resolved through mediation or arbitration. If arbitration fails, disputes may be resolved in the courts of the agreed jurisdiction.
        </p>

        <h3 className="policy-subtitle">Force Majeure</h3>
        <p className="policy-text">
          Neither party shall be liable for delays or failure to perform due to events beyond reasonable control, such as natural disasters, governmental actions, or interruptions in telecommunications.
        </p>

        <h3 className="policy-subtitle">Amendments</h3>
        <p className="policy-text">
          These Terms may only be amended in writing with mutual consent. Users will be notified of significant changes via email or website notifications.
        </p>

      </div>

      <div className="policy-section privacy-section">
        <h2 className="policy-title">Privacy Policy</h2>
        <p className="policy-text">
          Your privacy is important to us. We collect personal information, including names and emails, to facilitate account creation and service delivery. Google Analytics is used to understand service usage trends.
        </p>
        <p className="policy-text">
          We do not sell or share your personal information with outside parties without consent, except as required by law. Users may review and update their information at any time.
        </p>
        <p className="policy-text">
          Updates to our Privacy Policy will be communicated through email or website notifications. Continued use of our services signifies agreement to the updated policy.
        </p>
      </div>

    </div>
  );
}

export default TermsAndConditionsAndPrivacyPolicy;
