import React, { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import GenerateFirstDraftSBIR from './GenerateFirstDraftSBIR'; // Adjust the import path as necessary
import { $getRoot } from 'lexical';
import { $convertToMarkdownString } from '@lexical/markdown';
import { TRANSFORMERS } from '@lexical/markdown';
import axios from 'axios';

const GenerateFirstDraftWithLexicalSBIR = (props) => {
  const [editor] = useLexicalComposerContext();
  const { outlineHeaders, outlineTitle, selectedTopics, volumeTag, proposalProcessName, stage, handleResponseFromEditOutline, token, aiOutlinePromptMessage, groupId, companyName, setIsGeneratingFirstDraftPopup, setCurrentFirstDraftStep } = props;

  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  const [uploadSuccess, setUploadSuccess] = useState(false); // State to manage upload success


  const handlePrepareData = () => {
    const editorState = editor.getEditorState();
    const editorStateTextString = editorState.read(() => $getRoot().getTextContent());

    console.log("editorStateTextString", editorStateTextString);
    console.log("outlineHeaders", outlineHeaders);
    console.log("AI Prompt for first draft:", aiOutlinePromptMessage);

    const effectiveOutlineHeaders = outlineHeaders;

    return {
      outlineHeaders: effectiveOutlineHeaders,
      outlineTitle,
      selectedTopics,
    };
  };

  const handleSave = () => {
      editor.update(() => {
        const root = $getRoot();
        const markdown = $convertToMarkdownString(TRANSFORMERS);

        // Append "_outline" to the volumeTag for this save operation
        const modifiedVolumeTag = `${volumeTag}_outline`;
        console.log("Modified volumeTag:", modifiedVolumeTag);

        if (/^[a-z_]+_draft_volume_(I|II|III|IV)_outline$/.test(modifiedVolumeTag)) {
          console.log("Modified volumeTag contains outline");
          handleResponseFromEditOutline(markdown, modifiedVolumeTag, stage);
          setUploadSuccess(true);

          setTimeout(() => setUploadSuccess(false), 2000);
        } else {
          console.log("Modified volumeTag does not match outline pattern. Proceeding with file conversion and upload.");
          axios.post(`${BASE_URL}/govex/convert`, markdown, {
            responseType: 'blob',
            headers: {
              'Content-Type': 'text/plain',
              'X-Proposal-Name': proposalProcessName,
            },
          })
            .then((response) => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const contentDisposition = response.headers['content-disposition'];
              let fileName = 'download.docx';
              if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch && fileNameMatch.length > 1) {
                  fileName = fileNameMatch[1];
                }
              }
              const blob = new Blob([response.data]);
              const file = new File([blob], fileName, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
              const formData = new FormData();
              formData.append('productName', proposalProcessName);
              formData.append('productFiles', file);
              formData.append('tag', modifiedVolumeTag);
              formData.append('groupID', groupId);
              formData.append('companyName', companyName);
              axios.post(`${BASE_URL}/govex/upload`, formData, {
                headers: {
                  Authorization: `Bearer ${token}`,  // Use the token prop here
                },
              })
                .then(() => {
                  setUploadSuccess(true);
                  setTimeout(() => setUploadSuccess(false), 2000);
                })
                .catch((error) => {
                  console.error('Error uploading files:', error);
                });
            })
            .catch((error) => {
              console.error('Error downloading the document:', error);
            });
        }
      });
    };





  const preparedProps = { ...props, ...handlePrepareData(), handleSave };

  return <GenerateFirstDraftSBIR {...preparedProps} />;
};

export default GenerateFirstDraftWithLexicalSBIR;
