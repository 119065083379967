import React from 'react';
import './StepIndicator.css';

const StepIndicator = ({ steps, currentStep, setSelectedTab, nextButtonActive }) => {
  const currentStepIndex = steps.findIndex(step => step.key === currentStep);
  const totalSteps = steps.length;

  // Adjust the calculation to ensure it spans correctly between steps
  const progressBarWidth = `calc(${currentStepIndex * 12.5}% - 6%)`;


  const handleStepClick = (index, stepKey) => {
    //if (index <= currentStepIndex || (index === currentStepIndex + 1 && nextButtonActive)) {
    //  setSelectedTab(stepKey);
    //}
    console.log("stepKey - ", stepKey);
  };

  return (
    <div className="step-indicator-container" id="step2">
      <div className="progress-bar" style={{ width: progressBarWidth }}></div>
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${index === currentStepIndex ? 'active' : ''} ${index <= currentStepIndex || (index === currentStepIndex + 1 && nextButtonActive) ? 'clickable' : ''}`}
          onClick={() => handleStepClick(index, step.key)}
        >
          <div className={`step-icon ${index < currentStepIndex ? 'completed' : ''}`}>
            {index < currentStepIndex ? '\u2713' : index + 1}
          </div>
          <div className="step-label">{step.label}</div>
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;
