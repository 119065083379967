import React from 'react';
import './Documentation.css';

function Documentation() {
  return (
    <section className="documentation-section">
      <h2 className="documentation-heading">Documentation</h2>
      <p className="documentation-text">Explore our comprehensive documentation to get started with our products and solutions.</p>
      <a href="/documentation" className="documentation-link">View Documentation</a>
    </section>
  );
}

export default Documentation;
